<template>
  <div justify="center">
    <v-btn
      color="secondary"
      outlined
      elevation="0"
      @click.stop="openForm"
    >
      <v-icon
        size="17"
        class="me-1"
      >
        {{ icons.mdiDotsHorizontal }}
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1100px"
    >
      <div id="customer-list">
        <v-card>
          <v-card-title>取引先検索</v-card-title>
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-text-field
              v-model="search"
              placeholder="Search"
              outlined
              hide-details
              dense
              class="user-search me-3 mb-4"
            >
            </v-text-field>
            <v-spacer></v-spacer>
          </v-card-text>
          <v-data-table
            v-model="selectedList"
            item-key="cust_id"
            :items="tableList"
            show-select
            single-select
            :headers="headers"
            sort-by
            class="elevation-0"
            :search="search"
          >
            <template v-slot:[`item.email`]="{ item }">
              <div class="cell-content">
                {{ item.email }}
              </div>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-2"
              @click="goBack"
            >
              <span>OK</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="dialog = false"
            >
              <span>キャンセル</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'

import {
  mdiDotsHorizontal,
} from '@mdi/js'

export default {
  props: ['id', 'selectedCustomer'],
  data: () => ({

    dialog: false,
    search: '',
    selectedList: [],
    icons: {
      mdiDotsHorizontal,
    },
    tableList: [],
    headers: [
      {
        text: 'ID',
        value: 'cust_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },
      {
        text: '取引先略称',
        value: 'cust_abbr',
        align: 'left',
        width: '22%',
        fixed: true,
      },

      {
        text: '取引先名',
        value: 'cust_name',
        width: '30%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '取引先名（ENG）',
        value: 'cust_name_eng',
        width: '30%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '国・地域',
        value: 'country_name',
        width: '10%',
        align: 'center',
        sortable: true,
        fixed: false,
      },

      // {
      //   text: 'E-mail',
      //   value: 'email',
      //   width: '20%',
      //   align: 'left',
      //   sortable: true,
      //   fixed: true,
      // },
      // {
      //   text: '携帯電話番号',
      //   value: 'tel',
      //   width: '16%',
      //   align: 'left',
      //   sortable: true,
      //   fixed: true,
      // },
    ],
  }),
  computed: {
    ...mapState('customerStore', ['allowedCustomers']),
  },
  watch: {
    selectedCustomer(val) {
      this.selectedList = val
    },

    dialog() {
      if (this.id) {
        const item = this.tableList.find(cl => cl.cust_id === this.id)
        this.selectedList.push(item)
      }
    },
  },
  methods: {
    ...mapActions('customerStore', ['loadAllowedCustomers']),
    ...mapMutations('app', ['setOverlayStatus']),

    loadData() {
      this.setOverlayStatus(true)
      console.log('load,', this.allowedCustomers)
      this.loadAllowedCustomers()
        .then(() => {
          this.tableList = this.$deepCopy(this.allowedCustomers)
        })
        .finally(() => {
          this.dialog = true
          this.setOverlayStatus(false)
        })
    },

    openForm() {
      this.loadData()
    },

    goBack() {
      this.dialog = false
      console.log('cust:', this.selectedList)
      this.$emit('add_cuslist', this.selectedList)
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
.cell-content {
  flex: 1;
  word-break: break-all;
  min-width: 0;
}
</style>
