<template>
  <v-card>
    <v-card-title>契約(編集)</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div v-for="msg in apierror.messages" :key="msg">
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <template>
        <v-form class="ma-6 mb-0">
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label
                class="text-right"
              >契約ID</label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <span>{{ Form.cont_id }}</span>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label
                class="text-right"
              >取引先</label>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="$v.Form.cust_abbr.$model"
                :error-messages="custAbbrErrors"
                readonly
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <Customer
                :id="Form.cust_id"
                :selected-customer="selectedCustomer"
                @add_cuslist="getaddCusList"
              ></Customer>
            </v-col>
          </v-row>
          <v-row v-if="Form.cust_id">
            <v-col
              cols="12"
              md="2"
            >
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              {{ Form.cust_id }}:{{ Form.cust_name }}
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              {{ Form.cust_name_eng }}
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>契約日<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-menu
                v-model="isDateMenuOpen"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                eager
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="Form.cont_date"
                    :error-messages="contDateErrors"
                    class="header-inputs flex-grow-0"
                    readonly
                    outlined
                    dense
                    placeholder="契約日"
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="Form.cont_date" @input="isDateMenuOpen = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>マスターB/L</label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="Form.cont_bl"
                outlined
                dense
                placeholder="マスターB/L"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>取引方式<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="Form.ic_id"
                :items="incotermNameId"
                :error-messages="icIdErrors"
                outlined
                dense
                placeholder="取引方式"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>通貨<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="Form.ccy_id"
                :items="currencies"
                outlined
                dense
                :error-messages="ccyIdErrors"
                placeholder="通貨"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>目的地<span style="color: red">*</span></label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="Form.dest_id"
                outlined
                dense
                placeholder="目的地"
                :items="destinationNameId"
                :error-messages="destIdErrors"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>出荷予定日</label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-menu
                v-model="isSsdMenuOpen"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                eager
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="Form.cont_ssd"
                    class="header-inputs flex-grow-0"
                    placeholder="出荷予定日"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="Form.cont_ssd" @input="isSsdMenuOpen = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label>予定入金日</label>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-menu
                v-model="isEddMenuOpen"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                eager
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="Form.cont_edd"
                    class="header-inputs flex-grow-0"
                    placeholder="予定入金日"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="Form.cont_edd" @input="isEddMenuOpen = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <label>連絡事項</label>
            </v-col>
            <v-col
              cols="12"
              md="7"
            >
              <v-textarea
                v-model="$v.Form.cont_attn.$model"
                outlined
                dense
                :counter="200"
                :error-messages="contAttnErrors"
                placeholder="連絡事項"
                hide-details="auto"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <v-card-actions
        align="center"
        class="d-flex justify-center"
      >
        <v-btn
          color="primary"
          :loading="submitStatus"
          @click="submit(Form)"
        >
          Submit
        </v-btn>
        <v-btn
          outlined
          @click="cancelClick"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import {
  mdiSendOutline,
  mdiPlus,
  mdiPencil,
  mdiClose,
  mdiCogOutline,
  mdiMinusCircle,
  mdiMenuUp,
  mdiMenuDown,
} from '@mdi/js'
import {
  ref,

  // reactive,
} from '@vue/composition-api'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
import {
  required, maxLength,
} from 'vuelidate/lib/validators'
import Customer from '@/components/Customer.vue'

export default {
  components: {
    Customer,
  },
  setup() {
    const items = []
    const isDateMenuOpen = ref(false)
    const isSsdMenuOpen = ref(false)
    const isEddMenuOpen = ref(false)
    const editStatus = ref(false)
    const clients = ref([])

    const setEditStatus = () => {
      editStatus.value = true
      console.log('---------->setName', editStatus.value)
    }

    return {
      isDateMenuOpen,
      isSsdMenuOpen,
      isEddMenuOpen,
      items,
      editStatus,
      clients,
      icons: {
        mdiSendOutline,
        mdiPlus,
        mdiPencil,
        mdiMinusCircle,
        mdiClose,
        mdiCogOutline,
        mdiMenuUp,
        mdiMenuDown,
      },

      setEditStatus,
    }
  },
  data: () => ({
    submitStatus: false,
    apierror: [],
    detailErrors: false,
    selectedCustomer: [],
    Form: {
      cust_abbr: '',
      cust_id: '',
      cont_attn: '',
      cont_date: '',
      cont_bl: '',
      ccy_id: '',
      ccy_code: '',
      ic_id: '',
      ic_code: '',
      tmpl_id: '',
      dest_id: '',
      cont_ssd: '',
      cont_edd: '',
    },
  }),
  validations: {
    Form: {
      cont_date: {
        required,
      },
      cust_abbr: {
        required,
      },
      ic_id: {
        required,
      },
      ccy_id: {
        required,
      },
      dest_id: {
        required,
      },
      cont_attn: {
        maxLength: maxLength(200),
      },
    },
  },
  computed: {
    ...mapState('pelletStore', ['pelletIds', 'pelletNameEn']),
    ...mapState('incotermStore', ['incotermNameId']),
    ...mapState('currencyStore', ['currencies']),
    ...mapState('destinationStore', ['destinationNameId']),
    ...mapState('contractStore', ['selectedContract']),
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    contDateErrors() {
      const errors = []
      if (!this.$v.Form.cont_date.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.cont_date.required && errors.push('必須項目')

      return errors
    },
    icIdErrors() {
      const errors = []
      if (!this.$v.Form.ic_id.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.ic_id.required && errors.push('必須項目')

      return errors
    },
    ccyIdErrors() {
      const errors = []
      if (!this.$v.Form.ccy_id.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.ccy_id.required && errors.push('必須項目')

      return errors
    },

    custAbbrErrors() {
      const errors = []

      if (!this.$v.Form.cust_abbr.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.cust_abbr.required && errors.push('必須項目')

      return errors
    },
    destIdErrors() {
      const errors = []
      if (!this.$v.Form.dest_id.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.dest_id.required && errors.push('必須項目')

      return errors
    },
    contAttnErrors() {
      const errors = []
      if (!this.$v.Form.cont_attn.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.cont_attn.maxLength && errors.push('200文字以内にしてください。')

      return errors
    },
  },
  watch: {
    Form: {
      handler() {
        this.apierror.messages = []
      },
      deep: true,
    },
  },

  created() {
    this.loadData()
  },
  methods: {
    ...mapActions('pelletStore', ['loadPelletIds', 'loadPellets']),
    ...mapActions('contractStore', ['loadContract', 'editContract']),
    ...mapActions('incotermStore', ['loadIncotermList']),
    ...mapActions('currencyStore', ['loadCurrencyList']),
    ...mapActions('destinationStore', ['loadDestinationList']),
    ...mapMutations('app', ['setOverlayStatus']),
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        // this.loadPelletIds(),
        // this.loadPellets(),
        this.loadIncotermList(),
        this.loadCurrencyList(),
        this.loadDestinationList(),
        this.loadContract(this.$route.params.id),
      ]).then(() => {
        console.log('Form:', this.selectedContract.contract)
        this.Form = this.$deepCopy(this.selectedContract.contract)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    addContainer(Form) {
      const detailId = Form.containers.length + 1
      Form.containers.push({
        id: detailId,
        detail_id: detailId,
        product_id: '',
        cntr_no: '',
        seal_no: '',
        cntr_bl: '',
        cntr_weight: 0,
        cntr_amount: 0,
        showon: true,
        details: [],
      })
    },
    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },
    jumpToListPage() {
      this.$router.push({
        path: '/contract-list',
        query: this.$route.params.query ?? {
        },
      })
    },

    getaddCusList(selectCustomer) {
      this.selectedCustomer = selectCustomer
      console.log('selectCustomer', selectCustomer)
      if (selectCustomer.length === 1) {
        this.Form.cust_id = selectCustomer[0].cust_id
        this.Form.cust_abbr = selectCustomer[0].cust_abbr
        this.Form.cust_name = selectCustomer[0].cust_name
        this.Form.cust_name_eng = selectCustomer[0].cust_name_eng
      } else {
        this.Form.cust_id = ''
        this.Form.cust_abbr = ''
        this.Form.cust_name = ''
        this.Form.cust_name_eng = ''
      }
    },
    submit(Form) {
      this.submitStatus = true

      console.log('Submit:', Form)
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.editContract(Form)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          }).finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!')
        window.scrollTo(0, 0)
        this.apierror.status = 'error'
        if (this.apierror.messages.length === 0) {
          this.apierror.messages.push('赤い部分の情報を修正してください')
        }
        this.submitStatus = false
      }
    },
  },
}
</script>

<style>
.ql-editor {
  min-height: 120px;
}
</style>
